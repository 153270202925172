.rl-bg-dark-blue-light { background-color: #A6C9FA; fill: #A6C9FA;}
.rl-bg-dark-blue-dark { background-color: #1D41AB; fill: #1D41AB;}
.rl-bg-blue-light { background-color: #67E8F9; fill: #67E8F9;}
.rl-bg-blue-dark { background-color: #22D3EE; fill: #22D3EE;}
.rl-bg-green-light { background-color: #86EFAC; fill: #86EFAC;}
.rl-bg-green-dark { background-color: #4ADE80; fill: #4ADE80;}
.rl-bg-orange-light { background-color: #FED7AA; fill: #FED7AA;}
.rl-bg-orange-dark { background-color: #FF982C; fill: #FF982C;}
.rl-bg-purple-light { background-color: #DDD6FE; fill: #DDD6FE;}
.rl-bg-purple-dark { background-color: #C4B5FD; fill: #C4B5FD;}
.rl-bg-brick-light { background-color: #FFBDC5; fill: #FFBDC5;}
.rl-bg-brick-dark { background-color: #FC919E; fill: #FC919E;}
.rl-bg-pink-light { background-color: #FBCFE8; fill: #FBCFE8;}
.rl-bg-pink-dark { background-color: #F9A8D4; fill: #F9A8D4;}
.rl-bg-sand-light { background-color: #EDD79E; fill: #EDD79E;}
.rl-bg-sand-dark { background-color: #D5AF4E; fill: #D5AF4E;}
.rl-bg-teal-light { background-color: #99E3C9; fill: #99E3C9;}
.rl-bg-teal-dark { background-color: #54C5A5; fill: #54C5A5;}
.rl-bg-lime-light { background-color: #E5FE9E; fill: #E5FE9E;}
.rl-bg-lime-dark { background-color: #A3E635; fill: #A3E635;}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance:textfield;
}

label {
  margin-bottom: 7px
}

.form-group {
  @apply mb-5;
}

.form-control {
  @apply border text-gray-800;
  padding: 9px 12px;
  &[disabled] {
    @apply bg-gray-100 border-gray-300 text-gray-600;
  }
  &:focus {
    @apply border-blue-600;
    padding: 9px 12px;
  }
}

select.form-control, select.form-control:focus {
  height: 40px;
}

.form-control-with-icon {
  .form-control-icon-focus {
    @apply text-blue-600;
  }
}

.track {
  @apply relative h-1 bg-gray-200 rounded-lg;
}

.track.track-1 {
  @apply bg-blue-700 h-1 rounded-lg;
}

.track-single {
  @apply relative h-1 bg-blue-700 rounded-lg;
}

.track-single.track-single-1 {
  @apply bg-gray-200 h-1 rounded-lg;
}


.badge {
  padding: 5px 7px;
}

.badge-blue {
  @apply rounded-sm bg-blue-100 text-blue-900;
}
.badge-light-blue {
  @apply rounded-sm bg-blue-100 text-blue-900;
}
.badge-teal {
  @apply rounded-sm bg-teal-100 text-teal-900;
}
.badge-gray {
  @apply rounded-sm bg-gray-200 text-gray-900;
}
.badge-red {
  @apply rounded-sm bg-red-100 text-red-900;
}
.badge-yellow {
  @apply rounded-sm bg-yellow-100 text-gray-700;
}
.badge-orange {
  @apply rounded-sm bg-orange-200 text-orange-900;
}
.badge-blue-inverted {
  @apply rounded-sm bg-blue-700 text-gray-100;
}

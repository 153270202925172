// React DatePicker Overrides
@import "react-datepicker/dist/react-datepicker.css";

#app {
  .react-datepicker-popper {
    z-index: 1000;
  }

  .react-datepicker-wrapper {
    @apply w-full;
  }

  .react-datepicker {
    @apply font-sans;
    @apply text-sm;


    .react-datepicker__header {
      @apply bg-gray-100 text-sm;

      .react-datepicker__current-month {
        @apply text-sm;      
      }
    }

    .react-datepicker__triangle::after {
      border-bottom-color: theme('colors.gray.100');
    }

    .react-datepicker__day {
      @apply w-6 leading-loose;
    }

    .react-datepicker__day-name {
      @apply w-6 leading-loose;
    }

    .react-datepicker__day--selected {
      @apply bg-blue-700;
    }
  }

  .react-datepicker__time-container {
    @apply w-24;
  }

  .react-datepicker__time-box {
    @apply w-full text-left;
  }

  .react-datepicker--time-only {
    @apply overflow-hidden border-none shadow-md w-48;

    .react-datepicker__time-container {
      @apply w-full;
    }
  }

  .react-datepicker__close-icon::after {
    @apply bg-white text-lg font-semibold text-gray-700;
  }

  .react-datepicker--time-only .react-datepicker__triangle {
    @apply hidden;
  }
  
  .react-datepicker__time-list-item--selected {
    @apply bg-blue-700;
    color: white !important;
  }

  .react-datepicker__time-list-item--disabled, .react-datepicker__header--time, .react-datepicker__header--time--only {
    @apply hidden;
  }
}

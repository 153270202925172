.btn {
  @apply focus:outline-none focus:ring;
  @apply print:hidden #{!important};
}

// New button styles- 2023
// https://www.figma.com/file/m6UQvVNil4FPFKOlXmkh4S/WIP%3A-2022-TWG-Component-Library?node-id=0%3A1&t=TdEra1epsLTaR49f-0

.btn-primary, .btn-secondary, .btn-tertiary, .btn-danger, .btn-ghost, .btn-icon-only, .btn-mx-primary, .btn-no-colors {
  @apply border border-transparent rounded inline-flex justify-center items-center font-bold;
  line-height: 20px;
  padding: 9px 12px;
  font-size: 14px;
  &.btn-sm {
    max-height: 24px;
  }
  &:disabled {
    @apply cursor-not-allowed opacity-40;
    pointer-events: none;
  }
}

.btn-primary {
  @apply bg-blue-700 text-white;
  &:hover:not([disabled]) {
    @apply bg-blue-800;
  }
  &:focus {
    @apply border border-blue-900 bg-blue-800;
  }
}

.btn-secondary {
  @apply bg-white text-blue-700 border border-blue-800;
  &:hover:not([disabled]) {
    @apply bg-blue-50 text-blue-800;
  }
  &:focus {
    @apply bg-blue-50 text-blue-800;
  }
}

.btn-tertiary {
  @apply bg-gray-200 text-gray-700;
  &:hover:not([disabled]) {
    @apply bg-gray-300 text-gray-800;
  }
  &:focus {
    @apply border bg-gray-300 border-gray-500 text-gray-800;
  }
}

.btn-danger {
  @apply bg-red-700 text-white;
  &:hover:not([disabled]) {
    @apply bg-red-800;
  }
  &:focus {
    @apply border bg-red-800 border-red-900;
  }
}

.btn-ghost {
  @apply bg-transparent text-blue-700;
  &:hover:not([disabled]) {
    @apply bg-blue-50 text-blue-800;
  }
  &:focus {
    @apply border bg-blue-50 border-blue-800;
  }
}

.btn-ghost-tertiary {
  @apply bg-transparent text-gray-600;
  &:hover:not([disabled]) {
    @apply bg-gray-200;
  }
  &:focus {
    @apply border bg-gray-300;
  }
}

// Marina X
.btn-mx-primary {
  @apply bg-mx-dark text-white;
  &:hover:not([disabled]) {
    @apply bg-mx-dark/80;
  }
  &:focus {
    @apply border border-mx-dark bg-mx-dark;
  }
}


.btn-icon-only {
  width: 40px;
  height: 40px;
  &.btn-sm {
    width: 24px;
    height: 24px;
  }
}

.text-link {
  @apply text-blue-700 cursor-pointer bg-transparent border-0 p-0 hover:text-blue-800 hover:underline focus:outline-none focus:ring;
}

.text-muted {
  @apply text-gray-600;
}

.stretched-link::after {
  content: "";
  @apply z-10 absolute inset-0 bg-transparent pointer-events-auto;
}

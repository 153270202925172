#metered-electric-panel { 
  height: calc(100vh - 52px)
}

@screen md {
  #metered-electric-panel {
    height: calc(100vh - 144px)
  }
}

@screen lg {
  #metered-electric-panel {
    height: calc(100vh - 65px)
  }
}

@import "tailwindcss/base";
@import "tailwind.preflight";
@import "tailwindcss/components";
@import "actiontext";

#app {
  @import "components/badge";
  @import "components/button";
  @import "features/reservation_labels";
  @import "features/metered_electric";
}

@import "components/border";
@import "components/card";
@import "components/datepicker";
@import "components/form";
@import "components/modal";
@import "components/richtext";
@import "components/table";
@import "components/text";

@import "tailwindcss/utilities";

@import "../../node_modules/react-international-phone/dist/index.css";
